import React from 'react';
import type { FC } from 'react';
import Text from 'components/Text';
import { useQuery } from 'styles/breakpoints';
import styled from 'styled-components';

interface ProgressCountProps {
  total: number;
  count: number;
}

const StyledText = styled(Text)`
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.024rem;
`;

const ProgressCount: FC<ProgressCountProps> = ({ total, count }) => {
  const { isTablet } = useQuery();
  return (
    <StyledText>
      <b>{count}</b> of {total}
    </StyledText>
  );
};

export default ProgressCount;

import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Text } from 'components';
import { tablet } from 'styles/breakpoints';

interface LoadingBarProps {
  duration: number;
  showPercents?: boolean;
  onComplete: () => void;
}

const ProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  height: 10px;
  background-color: #ffd9e7;
  position: relative;
  max-width: 27.75rem;
  width: 100%;
  border-radius: 16px;
`;

const ProgressFill = styled.div<{ progress: number; duration: number }>`
  height: 100%;
  background: #ef71a0;
  position: absolute;
  border-radius: 16px;
  width: ${({ progress }) => progress}%;
  transition: width ${({ duration }) => duration}s linear;
`;

const StyledPercentsText = styled(Text)`
  color: #ef71a0;
  text-align: center;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2rem;
  padding-top: 0.75rem;
  @media ${tablet} {
    padding-top: 0.55rem;
  }
`;

const LoadingBar: FC<LoadingBarProps> = ({
  duration,
  showPercents,
  onComplete,
}) => {
  const [progress, setProgress] = useState(0);
  const [progressPercents, setProgressPercents] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress(prevProgress => {
        const newProgress = prevProgress + 100 / duration;
        return newProgress >= 100 ? 100 : newProgress;
      });
    }, 10);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      onComplete();
    }, duration * 1000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [duration, onComplete]);

  useEffect(() => {
    const onePercentTimeIteration = (duration * 1000) / 100;

    const intervalId = setInterval(() => {
      setProgressPercents(prevProgress =>
        prevProgress > 100 ? 100 : prevProgress + 1,
      );
    }, onePercentTimeIteration);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      onComplete();
    }, duration * 1000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [duration, onComplete]);

  return (
    <ProgressBar>
      <ProgressFill progress={progress} duration={duration} />
      {showPercents && (
        <StyledPercentsText>{progressPercents}%</StyledPercentsText>
      )}
    </ProgressBar>
  );
};

export default LoadingBar;

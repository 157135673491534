import { DynamicImage, Seo, Text } from 'components';
import Header from 'components/Header';

import React from 'react';
import styled from 'styled-components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useFirstVisitDate, usePageView, useQuizData } from 'utils/hooks';
import 'swiper/swiper.min.css';
import LoadingBar from './components/LoadingBar';
import { useRouter } from 'apis/history';
import { Quiz } from 'types/quiz';
import BlackFridayBanner from 'components/BlackFridayBanner';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

SwiperCore.use([Autoplay]);

const Finalizing = () => {
  const data = useQuizData('finalizing');
  const quiz = new URLSearchParams(location.search).get('qz') ?? Quiz.Main;
  const { countdownDiffInMS } = useFirstVisitDate();
  const config = useSelector((state: AppState) => state.config);
  const variant = config?.variant;
  const { goToEmail, goToResults } = useRouter();

  const { isMobile } = useQuery();

  usePageView();
  const handleNavigationOnCounterEnd = () => {
    if (variant === 'funnel-email' || variant === 'email-first') {
      return goToEmail();
    }
    goToResults();
  };

  if (!data) {
    return null;
  }

  return (
    <>
      {data?.bfBanner ? (
        <BlackFridayBanner
          countdownDiffInMS={countdownDiffInMS}
          {...data.bfBanner}
        />
      ) : null}

      <Header
        logoVariant="center"
        sticky={false}
        color="light0"
        hasDivider={true}
      />
      <Container>
        {data.title && (
          <Title type={isMobile ? 'h1700' : 'h13Rem'}>{data.title}</Title>
        )}
        <StyledSwiper
          slidesPerView={1}
          noSwiping={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
            stopOnLastSlide: true,
          }}
        >
          {data.imageList.map((item: string, index: number) => (
            <StyledSwiperSlide key={index}>
              <StyledTitle>{item?.text}</StyledTitle>
              <SlideImage src={item.img} alt="" />
            </StyledSwiperSlide>
          ))}
        </StyledSwiper>
        <StyledLoadingBar
          onComplete={handleNavigationOnCounterEnd}
          duration={7}
          showPercents
        />
      </Container>
    </>
  );
};

export default Finalizing;

const Title = styled(Text).attrs({
  color: 'dark100',
})`
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.75rem;
  font-weight: 700;
`;

const StyledLoadingBar = styled(LoadingBar)``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.5rem 0;
  background-color: #fff;
  height: calc(100vh - 68px);
  @media ${tablet} {
    align-tems: center;
    padding: 1.5rem 2rem;
  }
`;

const StyledSwiper = styled(Swiper)`
  max-width: 28.75rem;
  width: 100%;
  min-height: 200px;
  pointer-events: none;
  margin-bottom: 3rem;
`;
const StyledSwiperSlide = styled(SwiperSlide)`
  width: 311px;
  min-height: 200px;
  pointer-events: none;
`;

const SlideImage = styled(DynamicImage)`
  max-width: 26.67425rem;
  width: 100%;
  margin: 0 auto;
`;

const StyledTitle = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4375rem;
  margin-bottom: 3rem;
  @media ${tablet} {
    font-size: 1.125rem;
    font-style: normal;
    line-height: 2rem;
    margin-bottom: 2.625rem;
  }
`;
